import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
//import reportWebVitals from './reportWebVitals'

// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'

// Bugsnag.start({
//   apiKey: '7484aa0d3e9b0e5db70aaaae0ec2d81d',
//   plugins: [new BugsnagPluginReact()]
// })

//const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary> */}
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
