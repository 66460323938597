import React, { Fragment, useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { cache, fetchData, getParam } from "../../utils/net"
import { getStr, userLang } from "../../utils/simpleLocalize"
import { Image } from "../Image"
import { Video } from "../Video"

import { IconArrowRight, IconClose } from "../icons"
import { Ratio } from '../Ratio'
import { isDebug } from "../../utils/tool"
import { Copyright } from "../Copyright"

import { headerState,soundOptions } from '../../AppAtoms'
import { useRecoilState } from "recoil"


import './novel.sass'
import { ContentHeader } from "../ContentHeader"


export const Novel = (props) => {
  const [rndVal, setrndVal] = useState(Math.random())
  const [_headerState,_setHeaderState] = useRecoilState(headerState)
  const [soundState,setSoundState] = useRecoilState(soundOptions)
  const [novelData, setNovelData] = useState()
  const [curScreens, setCurScreens] = useState([])
  const [screenHistory, setScreenHistory] = useState([])
  const [showList, setShowList] = useState(false)
  const wrap_ref = useRef()
  const history = useHistory()
  let { start } = useParams()

  const rnd = (v) => {
    return String(v) + '-' + rndVal
  }

  const handleAction = (s) => {
  }

  const nextSlide = (slideName) => {
    
    if (String(slideName).substring(0,3) === 'url'){

      var [opt,value] = slideName.split(':')
      switch (opt.toLowerCase()){
        case 'url':
          history.push(value)
          return
          break
        default:
      }      
    }

    var firstEl = wrap_ref.current.querySelector('.novel-screen')
    var box = firstEl && firstEl.getBoundingClientRect()
    var screens = [...curScreens]    
    if (!novelData[slideName]) {
      console.warn('ERROR SLIDE NOT FOUND', slideName, ' IN::', Object.keys(novelData))
      return
    }
    screens.push(novelData[slideName])
    if (box && box.right < 100) screens.shift()
    setCurScreens(screens)


    if (!screenHistory) {
      setScreenHistory([slideName])
    }else{
      var r = [...screenHistory]
      r.push(slideName)
      setScreenHistory(r)
    }
  }

  const prevSlide = () => {
    
  }

  //
  const onSelectSlide = (idx) => {
    if (typeof idx == 'string') {
      idx = novelData[idx]
    } 
    setrndVal(Math.random())
    setCurScreens([idx])
    setShowList(false)
  }


  
  //
  const onClose = () =>{
    var refpage = getParam('', 'ref')
    var path = refpage ? refpage : '/start'
    history.push(path)
  }

  //
  useEffect(() => {
    if (!novelData) return
    nextSlide(start)
  }, [novelData])

  //
  useEffect(() => {
    if (!start) {
      return
    }

    fetchData('/api/novel/' + userLang, (data) => {
      if (data) setNovelData(data)
      var music = cache.get('global_settings','music_novel')
      setSoundState(music)
    })

    _setHeaderState('hide')
    return ()=>{
      _setHeaderState('')
    }
  }, [start])

  if (!start || !curScreens) return <Fragment />

  return (
    <div className="novel">
      <ContentHeader title={getStr('novel.title') } onClick={ ()=>onClose() }></ContentHeader>
      <div className="pt-96px sm:pt-128px" />
      <div className="wrapper" ref={wrap_ref}>
        {curScreens.map((scr, i) => {
          if (scr.options && scr.options.type === 'menu') {
            return <NovelMenu key={rnd(scr._id)} idx={curScreens.length-i} data={scr} onNext={(e) => { onSelectSlide(e) }} />
          } else {
            return <NovelScreen key={rnd(scr._id)} idx={curScreens.length-i} data={scr} onNext={(e) => { nextSlide(e) }} />
          }
        })}
      </div>

      {isDebug() && <div className="absolute bottom-0 p-1 bg-red-100"><button className="" onClick={e => setShowList(true)}>LIST</button></div>}
      {isDebug() && showList && <SlidePreview data={novelData} onSelect={e => { onSelectSlide(e) }} onClose={e => setShowList(false)} />}
      {/* {isDebug() && <ImageList data={novelData} />} */}
    </div>
  )
}


/**
 * 
 * @param {*} param0 
 * @returns 
 */
const NovelMenu = ({ data, idx, onNext }) => {

  return (
    <div className={`novel-screen menu relative flex-col idx-${idx}`} data-name={data.title}>
      <div className={`content relative h-full`} style={{ width: `${data.desktop_width}vw` }}>
        {data.bg_image && <Image className="img absolute" src={data.bg_image.path} type="novel" />}
        {data.bg_video && <Video video_name={data.bg_video} active={true} loop={true} />}
      </div>
      <div className="bg-white-default ">
        <div className="p-2 text-black-default">{data.buttons_text}</div>
        <div className="flex">
          {data.buttons && data.buttons.map((el, i) => {

            if (el.settings.next) {
              return <button aria-label={getStr('ui.next')} key={i} className="flex bg-black-default nav items-center mr-1" onClick={() => onNext(el.settings.next.display)}>
                <span className="text-h4 md:text-h3 pl-2">{el.settings.title}</span>
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32.7764 21.6684L30.2163 24.2114L35.7699 29.765H21.064L21.064 33.3548H35.7699L30.2163 38.9084L32.7764 41.4514L42.6454 31.5599L32.7764 21.6684Z" fill="currentcolor"></path></svg>
              </button>
            } else {
              return <div key={i} className="flex bg-uigrey-light nav items-center p-1 md:py-2 md:px-3 mr-1" >
                <span className="text-h4 md:text-h3">{el.settings.title}</span>
              </div>
            }
          })}
          <div className="flex-1"></div>
          <button aria-label={getStr('ui.next')} className="flex bg-black-default nav items-center mr-1" onClick={() => onNext('start')}>
            {/* <span className="text-h4 md:text-h3 pl-2">Start</span> */}
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.0808 17.0001C24.6442 17.0999 25.2062 17.2084 25.7711 17.2981C28.5514 17.7392 31.3326 18.1747 34.1131 18.6142C34.3435 18.6506 34.5793 18.6774 34.7994 18.7511C35.3877 18.9482 35.6157 19.5363 35.3391 20.1088C35.2741 20.2433 35.196 20.3724 35.113 20.4961C33.1695 23.395 31.2247 26.293 29.2793 29.1904C29.2016 29.3061 29.1219 29.4217 29.0315 29.5267C28.5621 30.0716 27.8884 30.0089 27.5647 29.3621C27.3102 28.8535 27.1093 28.3161 26.8882 27.7898C26.602 27.1085 26.3194 26.4255 26.0242 25.7166C25.3471 26.1767 24.7752 26.7172 24.2563 27.3173C21.5224 30.4789 20.9803 35.0934 22.9607 38.8093C24.426 41.5586 26.6727 43.2897 29.6556 43.8971C33.8386 44.7489 38.0011 42.6987 40.0477 38.8284C40.7737 37.4554 41.1717 35.9798 41.2134 34.4096C41.2312 33.7401 41.3114 33.0945 41.8274 32.614C42.4272 32.0555 43.1204 31.9115 43.8599 32.2421C44.6377 32.5898 45.0179 33.2543 44.9993 34.1178C44.9057 38.4722 43.2485 42.0651 40.0145 44.8495C37.9536 46.6239 35.5586 47.629 32.8959 47.9226C26.146 48.6667 19.8357 43.9798 18.3571 37.1218C18.2072 36.4263 18.1514 35.7091 18.0506 35.0021C18.0379 34.9134 18.0171 34.8258 18 34.7377C18 34.2937 18 33.8498 18 33.4059C18.0155 33.2973 18.0386 33.1893 18.0454 33.0802C18.1238 31.8158 18.3619 30.5832 18.7767 29.3928C19.8298 26.3706 21.6627 23.9843 24.2696 22.2377C24.3492 22.1844 24.4241 22.1237 24.5061 22.0627C24.0029 20.8489 23.5034 19.6584 23.0158 18.4627C22.7211 17.7399 22.8772 17.3358 23.5546 17.0001L24.0808 17.0001L24.0808 17.0001Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}


const NovelScreen = ({ data,idx, onNext }) => {

  const [isActive, setIsActive] = useState(true)
  const [textItems, setTextItems] = useState([])
  const [textIndex, setTextIndex] = useState(-1)
  const [buttonList, setButtonList] = useState()

  const nextStep = () => {
    if (textItems && textIndex < textItems.length - 1) {
      setTextIndex(-1)
      setTimeout(() => {
        setTextIndex(textIndex + 1)
      }, 400)
    } else if (data.buttons && data.buttons.length > 0) {
      setButtonList(data.buttons)
      setIsActive(false)
    } else {
      setIsActive(false)
      if (data.next_screen){
        onNext(data.next_screen.display)

      }
    }
  }

  const buttonClick = (e) => {
    setButtonList(null)
    setIsActive(false)
    if (e.next && e.next.display){
      onNext(e.next.display)
    }else if (e.options && e.options.url ){
      onNext('url:'+e.options.url)
    }
  }

  const getShift = (s) => {
    return s ? {'marginTop':s} : {}
  }

  useEffect(() => {
    setTextItems(data.text)
    setTextIndex(0)
  }, [data])

  let curText = (textIndex != -1 && textItems) ? textItems[textIndex] : null

  if (Array.isArray(data.bg_image)) {
    data.bg_image = null
  }

  const getCopyright = ()=>{
    if (data.bg_image && data.bg_image.meta){
      return <Copyright data={data.bg_image.meta} type="meta" />
    } else if (data.options && data.options.copyright){
      return <Copyright data={data.options.copyright} type="text" />
    }
  }

  return (
    <div className={`novel-screen relative idx-${idx}`} data-name={data.title}>
      <div className={`content relative h-full ${buttonList ? 'hide' : ''}`} style={{ width: `${data.desktop_width}vw` }}>

        {data.bg_image && <Image className="img" src={data.bg_image.path} type="novel" />}
        {data.bg_video && <Video video_name={data.bg_video} active={true} loop={true} />}

        {curText && <div style={getShift(curText.settings.shift)} className={`text-box ${curText.settings.type} ${curText.settings.position}`}>{curText.settings.text}<Stalk data={curText.settings.stalk} /></div>}
        {isActive && <button className="absolute bottom-0 right-0 bg-uigrey-black" onClick={e => nextStep()}><IconArrowRight /></button>}

        {getCopyright()}
        {isDebug() && <div className="absolute top-0">{data.title}</div>}

      </div>
      <div className="button-wrap sm:pr-2">
        <div>
          {buttonList && <div className="text-h3 pl-2">{data.buttons_text}</div>}
          {buttonList && buttonList.map((el, i) => {
            return <button aria-label={getStr('ui.next')} key={i} className="flex text-left text-xl items-center btn-hover-scale" onClick={() => buttonClick(el.settings)}>
              <IconArrowRight />
              {el.settings.title}
            </button>
          })}
        </div>
      </div>
    </div>
  )
}


const Stalk = ({ data }) => {
  const [stalk, ofs] = data ? data.split('_') : ''

  const getOfs = (dir) => {
    var _ofs = !ofs ? '50' : ofs
    if (dir == 'horz') return { left: _ofs + '%' }
    if (dir == 'vert') return { top: _ofs + '%' }
  }


  switch (stalk) {

    case 'topleft': return (
      <svg className="top" style={getOfs('horz')} width="83" height="77" viewBox="0 0 83 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.6267 52.7498L47.0422 75.511L81.9426 75.511L67.5271 52.7498L78.1491 17.0902L0.760752 1.15724L57.6641 25.436L32.6267 52.7498Z" fill="#B6D3EE" />
        <path d="M47.0422 75.511L32.6267 52.7498L57.6641 25.436L0.760752 1.15724L78.1491 17.0902L67.5271 52.7498L81.9426 75.511" stroke="black" strokeWidth="2" />
      </svg>)

    case 'top': return (
      <svg className="top" style={getOfs('horz')} width="45" height="77" viewBox="0 0 45 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.526 57.9101L40.7724 75.5103L4.6905 75.5105L1.78711 56.1141L25.2181 35.6402L12.3147 1.15769L43.3995 35.6402L20.526 57.9101Z" fill="#B6D3EE" />
        <path d="M41.0134 75.51L20.526 57.9094L43.3995 35.6395L12.3147 1.15703L25.2181 35.6395L1.78711 56.1135L4.6905 75.5098" stroke="black" strokeWidth="2" />
      </svg>
    )

    case 'toplong': return (
      <svg className="top" style={getOfs('horz')} width="45" height="124" viewBox="0 0 45 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.526 93.7716L40.7724 122.51L4.6905 122.51L1.78711 90.839L25.2181 57.4081L12.3147 1.10327L43.3995 57.4081L20.526 93.7716Z" fill="#B6D3EE" />
        <path d="M41.0134 122.51L20.526 93.7709L43.3995 57.4073L12.3147 1.10251L25.2181 57.4073L1.78711 90.8383L4.6905 122.51" stroke="black" strokeWidth="2" />
      </svg>)

    case 'topright': return (
      <svg className="top" width="102" height="63" style={getOfs('horz')} viewBox="0 0 102 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50.5744 45.5779L33.8825 61.5105L1.25781 61.5105L21.743 45.5779L7.32767 15.2293L101.408 1.29896L33.8825 33.4383L50.5744 45.5779Z" fill="#B6D3EE" />
        <path d="M33.882 61.5105L50.5739 45.5779L33.882 33.4383L101.407 1.29896L7.32719 15.2293L21.7425 45.5779L1.25732 61.5105" stroke="black" strokeWidth="2" />
      </svg>)

    case 'bottomleft': return (
      <svg className="bottom" style={getOfs('horz')} width="72" height="63" viewBox="0 0 72 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.6258 17.2651L38.3177 1.33252L70.9424 1.33252L50.4572 17.2651L64.8725 47.6138L0.792341 61.5441L48.3177 39.4047L21.6258 17.2651Z" fill="#B6D3EE" />
        <path d="M38.3177 1.33252L21.6258 17.2651L48.3177 39.4047L0.792341 61.5441L64.8725 47.6138L50.4572 17.2651L70.9424 1.33252" stroke="black" strokeWidth="2" />
      </svg>
    )

    case 'bottomright': return (
      <svg className="bottom" style={getOfs('horz')} width="83" height="77" viewBox="0 0 83 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50.573 24.0933L36.1575 1.33203L1.2571 1.33203L15.6726 24.0933L5.05066 59.7528L82.439 75.6858L25.5356 51.407L50.573 24.0933Z" fill="#B6D3EE" />
        <path d="M36.1575 1.33203L50.573 24.0933L25.5356 51.407L82.439 75.6858L5.05066 59.7528L15.6726 24.0933L1.2571 1.33203" stroke="black" strokeWidth="2" />
      </svg>)

    case 'bottom': return (
      <svg className="bottom" style={getOfs('horz')} width="45" height="77" viewBox="0 0 45 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.6737 18.9329L4.42734 1.33275L40.5092 1.33252L43.4126 20.7288L19.9816 41.2027L32.885 75.6851L1.80018 41.2027L24.6737 18.9329Z" fill="#B6D3EE" />
        <path d="M4.18628 1.33301L24.6737 18.9335L1.80018 41.2034L32.885 75.6857L19.9816 41.2034L43.4126 20.7295L40.5092 1.33318" stroke="black" strokeWidth="2" />
      </svg>)

    case 'bottomlong': return (
      <svg className="bottom" style={getOfs('horz')} width="45" height="124" viewBox="0 0 45 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.6737 30.0714L4.42734 1.3329L40.5092 1.33252L43.4126 33.004L19.9816 66.4349L32.885 122.74L1.80018 66.4349L24.6737 30.0714Z" fill="#B6D3EE" />
        <path d="M4.18628 1.33301L24.6737 30.0722L1.80018 66.4357L32.885 122.741L19.9816 66.4357L43.4126 33.0047L40.5092 1.33328" stroke="black" strokeWidth="2" />
      </svg>)
  }
  return <Fragment />
}

const ImageList = ({ data }) => {
  const [isOpen, setOpen] = useState(false)

  if (!data) return <Fragment />

  return <div style={{ maxHeight: '90%', overflowY: 'scroll' }}>
    <button onClick={() => setOpen(!isOpen)}>Show List of Images</button>

    {isOpen && <div className="pl-4">

      {Object.keys(data).map((e, i) => {
        var dataItem = data[e]
        let img = dataItem.bg_image ? dataItem.bg_image.path : ''
        img = String(img).replace('storage/uploads/', '')
        img = String(img).replace('storage/', '')
        if (img == 'undefined' || img === '') return <Fragment key={i}/>
        return <a key={i} href={img} className="block">{img}</a>
      })}
    </div>}
  </div>
}


const SlidePreview = ({ data, onSelect, onClose }) => {
  if (!data) return <Fragment />
  return (<div className="jump-list absolute">
    <header className="flex items-center w-full px-4">
      <div className="uppercase text-h5 flex-1">Liste</div>
      <div>
        <button className={`block`} onClick={e => onClose()}><IconClose /></button>
      </div>
    </header>
    <div className="wrapper">

      {Object.keys(data).sort((a,b)=>data[a].title > data[b].title ? 1 : -1).map((e, i) => {
        var dataItem = data[e]
        let img = dataItem.bg_image ? dataItem.bg_image.path : ''

        return <button key={e} className="list-item" onClick={e => onSelect(dataItem)}  >
          <div className="flex items-center relative">
            <div>
              <Ratio ratio="1x1" className="w-full" />
              {/* <Image src={img} type="thumb" className="absolute top-0" /> */}
            </div>
            <div className="pl-1 text-h4">{dataItem.title}</div>
          </div>
        </button>

      })}
    </div>
  </div>)
}